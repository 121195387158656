import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Collage from "../Components/ProjectComponents/Collage";
import SSCLogo from "../Components/ProjectComponents/SSCLogo";

const GraphicDesign = () => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm>
            <Collage />
          </Col>

          <Col sm>
            <SSCLogo />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GraphicDesign;
