import React from "react";
import ComputerWritingPhoto from "../images/computerWriting.png";
import "./style/project.css";

function SeniorThesisComponent() {
  return (
    <div>
      <h2 className="writingHeader">Some writing: Data Storage Devices and How They Personalized Computing</h2>
      <a href="https://michaelrivas.net/writing/MichaelRivas_dataStorage.pdf" target="_blank" rel="noopener noreferrer">
        <img src={ComputerWritingPhoto} height="400" width="400" alt="Data Storage Writing"></img>
      </a>
    </div>
  );
}

export default SeniorThesisComponent;
