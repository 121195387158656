import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import EmailDeveloper from "../Components/ProjectComponents/EmailDeveloper";
import EmailProject2 from "../Components/ProjectComponents/EmailProject2";
import EmailProject3 from "../Components/ProjectComponents/EmailProject3";

const EmailDevelopment = () => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col className="mb-5" md={4}>
            <EmailProject3 />
          </Col>
          <Col className="mb-5" md={4}>
            <EmailProject2 />
          </Col>
          <Col className="mb-5" md={4}>
            <EmailDeveloper />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EmailDevelopment;
