import React from "react";
import vuetodoPhoto from "../images/vuetodo.png";
import "./style/project.css";

function VueApp() {
  return (
    <div className="container">
      <a href="https://effervescent-pixie-2873ff.netlify.app/" target="_blank" rel="noopener noreferrer">
        <img alt="Vue.js Todo" src={vuetodoPhoto}></img>
        <div class="overlay">
          <div class="text">Vue.js: To do list!</div>
        </div>
      </a>
    </div>
  );
}

export default VueApp;
