import React from "react";
import BushwickSiteImg from "../images/bushwicksite.png";
import "./style/project.css";

function BushwickSite() {
  return (
    <div className="container">
      <a href="http://composingdigitalmedia.org/s17_dmtp/webs/mir/index.html" target="_blank" rel="noopener noreferrer">
        <img alt="Bushsite website" src={BushwickSiteImg}></img>
        <div class="overlay">
          <div class="text">Static HTML/CSS and Jquery: Bushwick site</div>
        </div>
      </a>
    </div>
  );
}

export default BushwickSite;
