import React from "react";
import "./ProfilePic.css";
import profilePic from "../images/profilePic.jpg";

function AboutPage() {
  return (
    <div className="body-profile">
      <img className="profile-pic" src={profilePic} alt="michael rivas" />
      <p className="bio-text">
        My name is Michael Rivas, and I was born and raised in New York city. I attended NYU and graduated in 2018 with a Bachelor’s of Science in Media, Culture, and Communication. Shortly after college, I started working for a media company --Digiday Media-- doing digital marketing, as well as specializing in lead generation/performance marketing. Although I enjoyed my time in digital marketing, I had the desire to pursue something that was more of a blend of technicality and creativity. For over the course of five years I created passion projects in which I developed frontend applications. I am self taught in React JS and have designed numerous web apps using React; Including this site. In addition to front end web development, I have enjoyed my experiences with graphic design and email development. <br /> <br />
        Please email me for a site or help with an existing site!{" "}
      </p>
    </div>
  );
}

export default AboutPage;
