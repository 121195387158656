import React from "react";
import AboutPage from "../Components/PageComponents/aboutPage";
import "./style/pages.css";

function About() {
  return (
    <div>
      <AboutPage />
    </div>
  );
}

export default About;
