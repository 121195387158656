import React from "react";
import emailProject2 from "../images/emailIMG2.png";
import "./style/project.css";

const EmailDevelopment = () => {
  return (
    <div className="email-divs">
      <h2>Email Developer</h2>
      <p>Mock Template design constructed using HTML/CSS. Click image to view HTML file and view page source.</p>
      <a href="https://michaelrivas.net/email.html" target="_blank" rel="noreferrer">
        <img className="emailDeveloper" src={emailProject2} alt="email developed using HTML/CSS" />
      </a>
    </div>
  );
};

export default EmailDevelopment;
