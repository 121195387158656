import React from "react";
import CollageImage from "../images/80.jpg";
import "./style/project.css";

function Collage() {
  return (
    <div>
      <h2>Design: 1980s Collage: Current Events</h2>
      <img className="collageArt" alt="collage of current events" src={CollageImage}></img>
    </div>
  );
}

export default Collage;
