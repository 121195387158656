import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BerkanaVideo from "../Components/ProjectComponents/BerkanaVideo";
import SummerCampVideo from "../Components/ProjectComponents/SSCVideo";

const Video = () => {
  return (
    <Container fluid className="mt-4">
      <Row>
        <Col className="mt-1">
          <BerkanaVideo />
        </Col>

        <Col className="mt-1">
          <SummerCampVideo />
        </Col>
      </Row>
    </Container>
  );
};

export default Video;
