import React from "react";
import SSCLogoImg from "../images/sscLogoWS.png";
import "./style/project.css";

function SSCLogo() {
  return (
    <div>
      <h2>Design: Saquarema Summer Camp Logo </h2>
      <img className="sscLogo" alt="Summer Camp Logo" src={SSCLogoImg}></img>
    </div>
  );
}

export default SSCLogo;
