import React from "react";
import letterJSImg from "../images/letterImage.png";
import "./style/project.css";

function LetterIntervalAppJS() {
  return (
    <div className="container">
      <a className="linkPhototoMysite" href="https://mediamikenyc.github.io/" target="blank">
        <img alt="letter interval app website" src={letterJSImg}></img>
        <div class="overlay">
          <div class="text">JavaScript: Letter Interval App</div>
        </div>
      </a>
    </div>
  );
}

export default LetterIntervalAppJS;
