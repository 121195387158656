import React from "react";
import EmailDev from "../images/emailImg.png";
import "./style/project.css";

const EmailDevelopment = () => {
  return (
    <div className="email-divs">
      <h2>Email Developer</h2>
      <p>Email Developer for Digiday Media-- Facebook email</p>
      <a href="https://michaelrivas.net/emailIndex2.html" target="_blank" rel="noreferrer">
        <img className="emailDeveloper" src={EmailDev} alt="email developed using HTML/CSS" />
      </a>
    </div>
  );
};

export default EmailDevelopment;
