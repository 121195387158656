import React from "react";
import SSCvideo from "../videos/SSCV4Web.mp4";
import SSCposter from "../images/SSCPoster.png";
import "./style/project.css";

function SummerCampVideo() {
  return (
    <div className="project-video-container top-spacing">
      <h2>Video: Promotional Video for Brazilian Jiu-jitsu Retreat</h2>
      <video className="video-size mt" controls poster={SSCposter}>
        <source src={SSCvideo} type="video/mp4" />
      </video>
    </div>
  );
}

export default SummerCampVideo;
