import React from "react";
import "./style/pages.css";
import ContactForm from "../Components/ContactForm/form";

function ContactPage() {
  return (
    <div className="mainDiv">
      <h2>Stay in touch</h2>
      <ContactForm />
    </div>
  );
}

export default ContactPage;
