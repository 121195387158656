import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SanctuaryMovement from "../Components/ProjectComponents/SanctuaryWriting";
import SeniorThesisComponent from "../Components/ProjectComponents/SeniorThesisWriting";

const Writing = () => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm>
            <SeniorThesisComponent />
          </Col>
          <Col sm>
            <SanctuaryMovement />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Writing;
