import React, { useState } from "react";
import Axios from "axios";
import "./form.css";
import SuccessFlashMessage from "../FlashMessages/SuccessFlashMessage";
import FailedFlashMessage from "../FlashMessages/FailedFlashMessage";
import { Form, Button } from "react-bootstrap";

function ContactInput(props) {
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [flashMessage, setFlashMessage] = useState(false);
  const [failedMessage, setFailedMessage] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await Axios.post("https://backendformyporfolio.herokuapp.com/register", { username, email, password: "hello7777777!!!" });
      console.log("user was successfully created");
      setFlashMessage(true);
      setUsername("");
      setEmail("");
    } catch (e) {
      console.log("there was an error");
      setFailedMessage(true);
    }
  }

  return (
    <div className="main-div">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="m-3" controlId="formGroupPassword">
          <Form.Label>Name</Form.Label>
          <Form.Control className="w-75 mx-auto border-dark" value={username} onChange={e => setUsername(e.target.value)} type="input" placeholder="Name" name="username" />
        </Form.Group>
        <Form.Group className="m-3" controlId="formGroupEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control className="w-75 mx-auto border-dark" value={email} onChange={e => setEmail(e.target.value)} type="text" email="Email" placeholder="Enter email" />
        </Form.Group>

        <Button variant="primary" size="md" type="submit">
          Submit
        </Button>
      </Form>

      {flashMessage ? <SuccessFlashMessage /> : null}
      {failedMessage ? <FailedFlashMessage /> : null}
    </div>
  );
}

export default ContactInput;
