import React from "react";
import berkanaVideo from "../videos/berkanavideo.mp4";
import posterImage from "../images/videoPoster.png";
import "./style/project.css";

const BerkanaVideo = () => {
  return (
    <div className="project-video-container">
      <h2>
        Video: <i>Berkana:</i> a documentary by Michael Rivas and Emma Rudd
      </h2>
      <video className="video-size" controls poster={posterImage}>
        <source src={berkanaVideo} type="video/mp4" />
      </video>
    </div>
  );
};

export default BerkanaVideo;
