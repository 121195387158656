import React from "react";
import LetterReactPhoto from "../images/letterReact.png";
import "./style/project.css";

function LetterIntervalAppReact() {
  return (
    <div className="container">
      <a href="https://letterintervalapp.com" target="blank" rel="noopener noreferrer">
        <img alt="Letter Interval App React" src={LetterReactPhoto}></img>
        <div class="overlay">
          <div class="text">React JS: Letter Interval App!</div>
        </div>
      </a>
    </div>
  );
}

export default LetterIntervalAppReact;
