import React from "react";
import SanctuaryPhoto from "../images/SanctuaryMovement.png";
import "./style/project.css";

function SanctuaryMovement() {
  return (
    <div>
      <h2 className="writingHeader">The Sanctuary Movement: An American Civilian Opposition to U.S Foreign Policy</h2>
      <a href="https://michaelrivas.net/writing/MichaelRivas_SanctuaryMovement.pdf" target="_blank" rel="noopener noreferrer">
        <img src={SanctuaryPhoto} width="400" height="400" alt="Sanctuary Movement writing"></img>
      </a>
    </div>
  );
}

export default SanctuaryMovement;
