import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//Importing project components
import LetterIntervalAppReact from "../Components/ProjectComponents/LetterIntervalAppReact";
import LetterIntervalAppJS from "../Components/ProjectComponents/LetterIntervalAppJS";
import BushwickSite from "../Components/ProjectComponents/BushwickSite";
import Todo from "../Components/ProjectComponents/todo";
import VueApp from "../Components/ProjectComponents/vueApp";

function WebDevelopment() {
  return (
    <div>
      <Container className="website-projects-container" fluid>
        <Row>
          <Col className="mb-5" md={4}>
            <LetterIntervalAppReact />
          </Col>
          <Col className="mb-5" md={4}>
            <LetterIntervalAppJS />
          </Col>
          <Col className="mb-5" md={4}>
            <BushwickSite />
          </Col>
          <Col className="mb-5" md={4}>
            <Todo />
          </Col>
          <Col className="mb-5" md={4}>
            <VueApp />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WebDevelopment;
