import React from "react";
import emailProject3 from "../images/bloombergEmail.png";
import "./style/project.css";

const EmailDevelopment = () => {
  return (
    <div className="email-divs">
      <h2>HTML Email Template for Bloomberg</h2>
      <p>Template for Bloomberg</p>
      <a href="https://michaelrivas.net/email2.html" target="_blank" rel="noreferrer">
        <img className="emailDeveloper" src={emailProject3} alt="email developed using HTML/CSS" />
      </a>
    </div>
  );
};

export default EmailDevelopment;
