import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

//imported components
import NarBar from "./Components/NavBar/NavBar";

//imported pages
import Home from "./Pages/Home";
import About from "./Pages/About";
import ContactPage from "./Pages/Contact";
import WebDevelopment from "./Pages/WebDevelopment";
import EmailDevelopment from "./Pages/EmailDevelopment";
import GraphicDesign from "./Pages/GraphicDesign";
import Video from "./Pages/Video";
import Writing from "./Pages/Writing";

function App() {
  return (
    <div className="App">
      <NarBar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<ContactPage />} />
          <Route path="/WebDevelopment" element={<WebDevelopment />} />
          <Route path="/EmailDevelopment" element={<EmailDevelopment />} />
          <Route path="/GraphicDesign" element={<GraphicDesign />} />
          <Route path="/Video" element={<Video />} />
          <Route path="/Writing" element={<Writing />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
