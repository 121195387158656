import React from "react";
import todoPhoto from "../images/todoPhoto.png";
import "./style/project.css";

function Todo() {
  return (
    <div className="container">
      <a href="https://glittering-klepon-adf204.netlify.app/" target="_blank" rel="noopener noreferrer">
        <img alt="Todo-app" src={todoPhoto}></img>
        <div class="overlay">
          <div class="text">React JS: Todo app: made with tutorial</div>
        </div>
      </a>
    </div>
  );
}

export default Todo;
